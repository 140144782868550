import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TextInput } from "../components/form/TextInput"
import { TextArea } from "../components/form/TextArea"
import { Button } from "../components/Button"
import { HeaderVariants } from "../components/header"

import classes from "./jobs.module.css"
import { jobs } from "../data/jobOpenings"

const JobsAccordion = ({ jobs, onClickApply }) => {
  const [activeItem, setActiveItem] = useState("")
  const makeActive = id => setActiveItem(id)
  const applyFor = job => {
    onClickApply(job)
    const form = document.body.querySelector(`.${classes.formTitle}`)
    console.log(form, classes.formTitle)
    if (form) {
      form.scrollIntoView({ behavior: "smooth" })
    }
  }
  useEffect(() => {
    if (!activeItem) return
    // const jobDetailForActiveItem = document.body.querySelector(
    //   `[data-job-id="${activeItem}"]`
    // )
    // // TODO: Replace this with an actual scroll considering fixed header
    // if (jobDetailForActiveItem) {
    //   const prevItem = jobDetailForActiveItem.previousSibling
    //   if (prevItem) {
    //     prevItem.scrollIntoView({
    //       behavior: "smooth",
    //     })
    //   } else {
    //     jobDetailForActiveItem.scrollIntoView({
    //       behavior: "smooth",
    //     })
    //   }
    // }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [activeItem])
  return (
    <ul className={classes.jobList}>
      {jobs.map(job => (
        <li key={job.id} className={classes.jobListItem} data-job-id={job.id}>
          <h2
            className={classNames(
              classes.jobTitle,
              activeItem === job.id && classes.activeJobTitle
            )}
            onClick={() =>
              activeItem !== job.id ? makeActive(job.id) : makeActive("")
            }
          >
            {job.title}
          </h2>
          {activeItem === job.id && (
            <div className={classes.jobDetails}>
              <h3 className={classes.jobDetailSectionTitle}>Job Brief</h3>
              <p className={classes.jobBrief}>{job.brief}</p>
              {job.responsibilities && job.responsibilities.length > 1 && (
                <>
                  <h3 className={classes.jobDetailSectionTitle}>
                    Responsibilities
                  </h3>
                  <ul className={classes.jobRequirementsList}>
                    {job.responsibilities.map(requirement => (
                      <li className={classes.jobRequirementsListItem}>
                        {requirement}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <h3 className={classes.jobDetailSectionTitle}>Requirements</h3>
              <ul className={classes.jobRequirementsList}>
                {job.requirements.map(requirement => (
                  <li
                    className={[
                      classes.jobRequirementsListItem,
                      requirement.bold === true
                        ? classes.jobRequirementsListItemBold
                        : "",
                    ].join(" ")}
                  >
                    {requirement.text || requirement}
                  </li>
                ))}
              </ul>
              <Button
                className={classes.applyNowButton}
                onClick={() => applyFor(job.id)}
              >
                apply now
              </Button>
            </div>
          )}
        </li>
      ))}
    </ul>
  )
}

const CVUpload = ({ onUploadCV, onRemoveCV, cvFiles }) => {
  // const [hasCV, setHasCV] = useState(false)
  const inputRef = useRef(null)
  const handleChange = ({ target }) => {
    if (target.files && target.files[0]) {
      // setHasCV(true)
      onUploadCV(target.files[0])
    } else {
      // setHasCV(false)
    }
  }
  const triggerFileChooser = () => {
    const { current } = inputRef
    current.click()
  }
  return (
    <React.Fragment>
      <input
        type="file"
        accept=".pdf,application/pdf,.docx,.doc"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleChange}
        required
      />
      <button
        type="button"
        onClick={triggerFileChooser}
        className={classNames(classes.cvUploadButton, "js-focus-visible")}
      >
        Upload CV
      </button>
      <ul className={classes.cvFileList}>
        {cvFiles.map(cvFile => (
          <li className={classes.cvFileItem}>
            <p className={classes.cvFileName}>{cvFile.name}</p>
            <button
              className={classes.cvFileItemDelete}
              onClick={() => onRemoveCV(cvFile)}
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

const ApplicationForm = ({ chosenRole, showLinkField }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    link: "",
    why: "",
    cvFiles: [],
  })
  const handleChange = (value, { target: { name } }) => {
    setFormData(formData => ({
      ...formData,
      [`${name}`]: value,
    }))
  }
  const handleCVUpload = cvFile => {
    setFormData(formData => {
      if (!formData.cvFiles.some(file => file.name === cvFile.name)) {
        return {
          ...formData,
          cvFiles: [...formData.cvFiles, cvFile],
        }
      } else {
        return formData
      }
    })
  }
  const handleCVRemoval = cvFile => {
    setFormData(formData => ({
      ...formData,
      cvFiles: formData.cvFiles.filter(file => file !== cvFile),
    }))
  }
  useEffect(() => {
    if (chosenRole) {
      handleChange(chosenRole, { target: { name: "role" } })
    }
  }, [chosenRole])
  return (
    <form>
      <div className={classes.dualInputRow}>
        <TextInput
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
        <TextInput
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div className={classes.dualInputRow}>
        <TextInput
          type="email"
          label="Email Address"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextInput
          type="tel"
          label="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className={showLinkField ? classes.dualInputRow : classes.inputRow}>
        <TextInput
          label="Desired Role"
          name="role"
          value={formData.role}
          onChange={handleChange}
          disabled={!!chosenRole}
          required
        />
        {showLinkField && (
          <TextInput
            label="Link to Github Profile / Portfolio"
            name="link"
            value={formData.link}
            onChange={handleChange}
            required={!!formData.role.trim()}
          />
        )}
      </div>
      <div className={classes.inputRow}>
        <TextArea
          label="Why do you want to work with us?"
          name="why"
          value={formData.why}
          onChange={handleChange}
          required
        />
      </div>
      <CVUpload
        cvFiles={formData.cvFiles}
        onUploadCV={handleCVUpload}
        onRemoveCV={handleCVRemoval}
      />
      <div className={classes.submitButtonRow}>
        <Button type="submit">SUBMIT</Button>
      </div>
    </form>
  )
}

const JobsPage = () => {
  const [chosenJob, setChosenJob] = useState(null)
  const chosenRole = chosenJob ? chosenJob.title : ""
  const onClickApply = jobId => {
    const job = jobs.find(job => job.id === jobId)
    setChosenJob(job)
  }
  // We show the link field if one of the following is true:
  // 1. It's a design or development job
  // 2. There are no open roles and anybody can apply
  const showLinkField =
    (chosenJob &&
      (chosenJob.type === "design" || chosenJob.type === "development")) ||
    jobs.length === 0
  return (
    <Layout pageTitle="Join Us" headerVariant={HeaderVariants.dark}>
      <SEO title="Apply" />
      <div className="container header-fixed-adjust">
        <div className="two-paned-view two-paned-view--1to2">
          <div className="left-pane">
            <h1 className={classes.openingsTitle}>job openings</h1>
            {jobs.length === 0 && (
              <React.Fragment>
                <p className={classes.openingsDescription}>
                  We currently have no job openings available right now.
                </p>
                <p className={classes.openingsDescription}>
                  But you can apply using the form if you're exceptional in your
                  field.
                </p>
              </React.Fragment>
            )}
            {jobs.length > 0 && (
              <JobsAccordion onClickApply={onClickApply} jobs={jobs} />
            )}
          </div>
          <div className="right-pane">
            <h1 className={classes.formTitle}>
              make cool stuff <br /> and get paid to do it
            </h1>
            <ApplicationForm
              chosenRole={chosenRole}
              showLinkField={showLinkField}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JobsPage
