export const jobs = [
  // {
  //   id: "accmanager",
  //   title: "Account Manager",
  //   brief: `We are looking for an Account Manager to create long-term, trusting relationships with our clients.
  //   The Account Manager's role is to oversee a portfolio of assigned clients, develop new business from existing clients,
  //   and actively seek new sales opportunities.`,
  //   requirements: [
  //     "3+ years Account Manager experience",
  //     `Demonstrate ability to communicate, present and influence key stakeholders at all levels of an organization, including executive and C-level`,
  //     "Solid experience with CRM software (e.g. Salesforce or HubSpot) and MS Office (particularly MS Excel)",
  //     "Experience delivering client-focused solutions to customer needs",
  //     "Proven ability to juggle multiple account management projects at a time, while maintaining sharp attention to detail",
  //     "Excellent listening, negotiation and presentation abilities",
  //     "Serve as the lead point of contact for all client ccount management matters",
  //     "Build and maintain strong, long-lasting client relationships",
  //     "Negotiate contracts and close agreements to maximize profits",
  //     "Develop trusted advisor relationships with key accounts, customer stakeholders and executive sponsors",
  //     "Ensure the timely and successful delivery of our solutions according to customer needs and objectives",
  //     "Clearly communicate the progress of monthly/quarterly initiatives to internal and external stakeholders",
  //     "Develop new business with existing clients and/or identify areas of improvement to meet sales quotas",
  //     "Forecast and track key account metrics (e.g. quarterly sales results and annual forecasts)",
  //     "Prepare results and account status",
  //     "Collaborate with sales team to identify and grow opportunities within territory",
  //     "Assist with challenging client requests or issue escalations as needed",
  //   ],
  //   type: "other"
  // },
  {
    id: "fedev",
    title: "Front-end Developer",
    brief: `We are looking for a qualified Front-end developer to join our team. You will be responsible for building the ‘client-side’ of our web applications. You should be able to translate our organisation and client needs into functional and appealing interactive applications. If you’re interested in creating a user-friendly environment by writing code and moving forward in your career, then this job is for you. We expect you to be a tech-savvy professional, who is curious about new digital technologies and aspires to combine usability with visual design. Ultimately, you should be able to create a functional and attractive digital environment for our organisation, ensuring great user experience.`,
    responsibilities: [
      "Use markup languages like HTML to create user-friendly web page",
      "Maintain and improve website",
      "Collaborate with back-end developers and web designers to improve usability",
      "Get feedback from, and build solutions for, users and clients",
      "Write functional requirement documents and guides",
      "Create quality mockups and prototypes",
      "Help back-end developers with coding and troubleshooting",
      "Ensure high quality graphic standards and brand consistency",
      "Stay up-to-date on emerging technologies",
    ],
    requirements: [
      {
        text: "Frameworks: React, React native, Angular JS",
        bold: true,
      },
      {
        text: "Languages: HTML, CSS, Javascript",
        bold: true,
      },
      "Proven work experience as a Front-end developer",
      "Hands on experience with markup languages",
      "Familiarity with browser testing and debugging",
      "Indepth understanding of the entire web development process (design, development and deployment)",
      "Understanding of layout aesthetics",
      "Knowledge of SEO principles",
      "Familiarity with software like Adobe Suite, Photoshop and content management systems",
      "An ability to perform well in a fast-paced environment",
      "Excellent analytical and multitasking skills",
    ],
    type: "development",
  },
  {
    id: "bedev",
    title: "Back-end Developer",
    brief: `We are looking for an experienced Back-end developer to join our team. You will be responsible for the server side of our web applications. If you have excellent programming skills and a passion for developing applications or improving existing ones, we would like to meet you. As a Back-end developer, you’ll work closely with our engineers to ensure system consistency and improve user experience. Ultimately, you should be able to develop and maintain functional and stable web applications to meet our organisation’s needs.`,
    responsibilities: [
      "Participate in the entire application lifecycle, focusing on coding and debugging",
      "Write clean code to develop functional web applications",
      "Collaborate with Front-end developers to integrate user-facing elements with server-side logic",
      "Manage cutting-edge technologies to improve legacy applications",
      "Gather and address technical and design requirements",
      "Provide training and support to internal teams",
      "Build reusable code and libraries for future use",
      "Follow emerging technologies",
      "Liase with developers, designers and system administrators to identify new features",
    ],
    requirements: [
      {
        text: "Frameworks: .Net",
        bold: true,
      },
      {
        text: "Database: Microsoft SQL server",
        bold: true,
      },
      "Proven work experience as a Back-end developer",
      "Working knowledge of CMS framework",
      "Familiarity with front-end languages (e.g. HTML, Javascript and CSS)",
      "Indepth understanding of the entire web development process (design, development and deployment)",
      "Excellent analytical and time management skills",
      "Teamwork skills with a problem-solving attitude",
      "Hands on experience with programming languages like Java, Ruby, Python, PHP",
      "An ability to perform well in a fast-paced environment",
    ],
    type: "development",
  },
]
