/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header, { HeaderVariants } from "./header"
import "./layout.css"
import Footer from "./footer"

const Layout = ({
  children,
  pageTitle,
  headerVariant = HeaderVariants.dark,
  footerVariant = "dark",
  noFooter = false,
  fixedFooter = false,
  background = false,
}) => {
  return (
    <div role="presentation" className="layout">
      <Header
        pageTitle={pageTitle}
        variant={headerVariant}
        background={background}
      />
      <div className="content">{children}</div>
      {!noFooter && <Footer variant={footerVariant} fixed={fixedFooter} />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
