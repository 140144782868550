import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"

import "./Button.css"

export const Button = ({
  children,
  to = undefined,
  onClick,
  className,
  ...props
}) => {
  if (to) {
    return (
      <Link
        to={to}
        className={classNames("Button js-focus-visible", className)}
        onClick={onClick}
        {...props}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={classNames("Button js-focus-visible", className)}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    )
  }
}
