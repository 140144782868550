import React, { useCallback } from "react"
import classNames from "classnames"

import "./TextArea.css"

export const TextArea = ({ label, value, onChange, className, ...props }) => {
  const handleChange = useCallback(
    event => {
      const {
        target: { value },
      } = event
      onChange(value, event)
    },
    [onChange]
  )
  return (
    <textarea
      // className={classNames("TextArea js-focus-visible", className)}
      className={classNames("TextArea", className)}
      value={value}
      onChange={handleChange}
      aria-label={label}
      placeholder={label}
      {...props}
    />
  )
}
